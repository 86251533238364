import { useContext, useState } from 'react';
import useFormState from './useFormState';
import { useTranslation } from './useTranslation';
import validationRules, { Model } from '../components/oregon-consumer-privacy-act/OCPAPublicForm.rules';
import { NotificationsContext } from '../providers/NotificationsProvider';
import useUtil from './pge-plus/useUtil';
import { useMutation } from '@apollo/react-hooks';
import { OCPAInformationMutation } from './useOCPAInfoForm';
import useWrapWithLoader from './useWrapWithLoading';
import {
  MutationOcpaFormSubmitArgs,
  OcpaFormSubmitResponse,
} from '../__generated__/pge-types';

export default () => {
  const { t } = useTranslation();
  const [isComplete, setComplete] = useState(false);
  const [ocpaOptionsReqError, setOcpaOptionsReqError] = useState(false);
  const notificationContext = useContext(NotificationsContext);

  const { wrapWithLoader } = useWrapWithLoader();
  const { setErrorNotification } = useUtil();
  const form = useFormState(
    {
      requestorFirstName: '',
      requestorMiddleName: '',
      requestorLastName: '',
      requestorPhoneType: '',
      requestorPhoneValue: '',
      requestorAddress: '',
      requestorCity: '',
      requestorState: '',
      requestorZip: '',
      requestorEmail: '',
      requestorConfirmEmail: '',
      requestorContactMailType: 'email',
      requestorIdType: '',
      requestorIdValue: '',
      personalInfoReport: false,
      deleteInfo: false,
      marketingCommunication: false,
      disclaimer: false,
      customerFirstName: '',
      customerMiddleName: '',
      customerLastName: '',
      customerPhoneType: '',
      customerPhoneValue: '',
      customerAddress: '',
      customerCity: '',
      customerState: '',
      customerZip: '',
      customerEmail: '',
      customerConfirmEmail: '',
      sameAsRequestor: 'someone'
    },
    {
      validate: validationRules,
    },
  );

  const [ocpaFormSubmitResponse] = useMutation<
    { ocpaFormSubmit: OcpaFormSubmitResponse },
    MutationOcpaFormSubmitArgs
  >(OCPAInformationMutation, {
    variables: {
      payload: {
        requestorInformation: {
          addressLine1: form.values.requestorAddress,
          city: form.values.requestorCity,
          state: form.values.requestorState,
          zipcode: form.values.requestorZip,
          contactPreference: form.values.requestorContactMailType,
          enteredEmail: form.values.requestorEmail,
          firstName: form.values.requestorFirstName,
          idTypeSelection: form.values.requestorIdType.toString(),
          last4Id: form.values.requestorIdValue,
          lastName: form.values.requestorLastName,
          middleName: form.values.requestorMiddleName,
          phoneContactType: form.values.requestorPhoneType,
          phoneNumber: form.values.requestorPhoneValue,
          requestDelete: form.values.deleteInfo ? 'Y' : 'N',
          requestReport: form.values.personalInfoReport ? 'Y' : 'N',
          requestOptOutMkt: form.values.marketingCommunication ? 'Y' : 'N',
        },
        requestAgreedTC: form.values.disclaimer ? 'Y' : 'N',
        customerInformation: {
          addressLine1: form.values.sameAsRequestor === 'someone' ? form.values.requestorAddress : form.values.customerAddress,
          city: form.values.sameAsRequestor  === 'someone' ? form.values.requestorCity : form.values.customerCity,
          state: form.values.sameAsRequestor  === 'someone' ? form.values.requestorState : form.values.customerState,
          zipcode: form.values.sameAsRequestor  === 'someone' ? form.values.requestorCity : form.values.customerZip,
          enteredEmail: form.values.sameAsRequestor  === 'someone' ? form.values.requestorEmail : form.values.customerEmail,
          firstName: form.values.sameAsRequestor  === 'someone' ? form.values.requestorFirstName : form.values.customerFirstName,
          lastName: form.values.sameAsRequestor  === 'someone' ? form.values.requestorLastName : form.values.customerLastName,
          middleName: form.values.sameAsRequestor  === 'someone' ? form.values.requestorMiddleName : form.values.customerMiddleName,
          phoneContactType: form.values.sameAsRequestor  === 'someone' ? form.values.requestorPhoneType : form.values.customerPhoneType,
          phoneNumber: form.values.sameAsRequestor  === 'someone' ? form.values.requestorPhoneValue : form.values.customerPhoneValue,
        },
      },
    },
    onCompleted: response => {
      console.log('ocpaFormSubmit',response);
      if (response?.ocpaFormSubmit?.isSuccessful) {
        setComplete(true);
      } else {
        notificationContext.setState({
          isOpen: true,
          message: t('GENERIC_ERROR_NOTIFICATION_MESSAGE_BODY'),
          severity: 'error',
        });
        if (typeof window !== 'undefined') {
          window.scrollTo(0, 0);
        }
      }
    },
  });

  const handleOCPAFormSubmit = wrapWithLoader(
    async (data: Model): Promise<void> => {
      if (
        !(
          data.personalInfoReport ||
          data.deleteInfo ||
          data.marketingCommunication
        )
      ) {
        {
          setOcpaOptionsReqError(true);
        }
        return;
      }

      try {
        await ocpaFormSubmitResponse();
      } catch (e) {
        setErrorNotification(true);
        return;
      }
    },
  );
  const handleSubmit = form.submit(handleOCPAFormSubmit);
  return {
    isComplete,
    ocpaOptionsReqError,
    form,
    handleSubmit,
  };
};

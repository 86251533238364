import React from 'react';
import useInstallerAuth from '../../hooks/pge-plus/useInstallerAuth';
import {
  Box,
  Checkbox,
  createStyles,
  Divider,
  FormControlLabel,
  FormHelperText,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Theme,
  Typography,
} from '@material-ui/core';
import { useTranslation } from '../../hooks/useTranslation';

import colors from '../../themes/main-colors';
import makeStyles from '@material-ui/styles/makeStyles';
import PhoneTextField from '../../components/phone-text-field';
import TextField from '../../components/text-field';
import Dropdown from '../../components/dropdown';
import useOCPAForm from '../../hooks/useOCPAPublicForm';
import ZipInputField from '../../components/zip-input-field';
import { statesAndProvincesGrouping } from '../../components/utility/state-dropdown';
import { getStateName } from '../../util/format';
import PGEButton from '../../components/buttons';
import TextLink from '../../components/text-link';

import ROUTES from '../../routes';
import _ from 'lodash';
import OCPASuccessForm from '../../components/oregon-consumer-privacy-act/OCPASuccessForm';

export interface OCPAProps {
  path: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cancelButton: {
      display: 'flex',
      alignItems: 'center',
      '& a': {
        background: colors.navBarBackground,
        border: '1px solid',
        borderColor: colors.sparkBlue,
        borderRadius: 4,
        padding: 8,
        color: colors.sparkBlue,
        textDecoration: 'none',
        '&:hover': {
          background: colors.navBarBackground,
          borderColor: colors.sparkBlue,
          color: colors.sparkBlue,
          textDecoration: 'none',
        },
      },
    },
    heading: {
      fontSize: theme.spacing(4.5),
      lineHeight: theme.typography.pxToRem(38),
      fontWeight: 'bold',
      letterSpacing: '0.720px',
      fontFamily: 'Forma-DJR-Display',
      color: colors.lightCyan,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.spacing(3),
        marginBottom: '16px',
      },
    },
    subHeading: {
      fontWeight: 'bold',
      fontSize: 18,
    },
    text: {
      color: '#20415B',
      fontSize: 16,
      '& a': {
        textDecoration: 'none',
      },
    },

    submit: {
      color: colors.navBarBackground,
      borderColor: colors.orange,
      backgroundColor: colors.orange,
      '&:hover': {
        backgroundColor: colors.orange,
        borderColor: colors.orange,
        cursor: 'pointer',
      },
    },
    whiteBox: {
      padding: theme.spacing(5),
      margin: '20px 20px',
      background: colors.navBarBackground,
    },
    radioLabel: { fontSize: '16px', fontWeight: 'bold' },
    discTrue: { margin: '0px 15px' },
    discFalse: {
      margin: '0px 15px',
      '& p': { fontWeight: 500, color: colors.errorRed },
    },
    errorMessage: {
      margin: '0 14px',
    },

    boxPadding: {
      padding: '0rem 1rem',
      [theme.breakpoints.down('sm')]: {
        padding: '1.5rem',
      },
    },
    dividerPadding: {
      margin: '2rem 0rem',
    },
  }),
);

const OCPA = (_props: OCPAProps) => {
  const { isAuthenticated } = useInstallerAuth();
  const { isComplete, ocpaOptionsReqError, form, handleSubmit } = useOCPAForm();
  const { t, richT } = useTranslation();
  const classes = useStyles();
  return isAuthenticated ? null : (
    <Grid container justify="center" alignItems="center">
      <Grid md={9} style={{ maxWidth: 1100 }}>
        {isComplete ? (
          <OCPASuccessForm />
        ) : (
          <Paper className={classes.whiteBox}>
            <form id={'OCPA-form'} noValidate onSubmit={handleSubmit}>
              <Box>
                <Typography className={classes.heading} variant="h1">
                  {t('OCPA_REQUEST_TITLE')}
                </Typography>
              </Box>
              <Box>
                <Typography className={classes.text}>
                  {richT('OCPA_REQUEST_INFO_TEXT')}
                </Typography>
              </Box>
              <Box>
                <Typography variant="h2" style={{ fontSize: '18px' }}>
                  {t('REQUESTOR_INFORMATION')}
                </Typography>
                <Typography className={classes.text}>
                  {richT('OCPA_REQUEST_INFO_SECTION_TEXT')}
                </Typography>
              </Box>
              <Grid container spacing={2}>
              <Grid item md={12}>
                  <Typography variant="h2" className={classes.subHeading} style={{marginTop:'16px'}}>
                    {t('SAME_AS_REQUESTOR')}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                  <RadioGroup
                    row
                    id={t('YOURSELF')}
                    aria-labelledby={t('YOURSELF')}
                    name={'yourself'}
                    value={form.props('sameAsRequestor').value}
                    onChange={form.props('sameAsRequestor').onChange}
                    onBlur={form.props('sameAsRequestor').onBlur}
                  >
                    <FormControlLabel
                      value={'yourself'}
                      control={<Radio color={'primary'} />}
                      label={
                        <Typography className={classes.radioLabel}>
                          {t('YOURSELF')}
                        </Typography>
                      }
                    />

                    <FormControlLabel
                      value={'someone'}
                      control={<Radio color={'primary'} />}
                      label={
                        <Typography className={classes.radioLabel}>
                          {t('SOMEONE')}
                        </Typography>
                      }
                    />
                  </RadioGroup>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                  <Typography variant="h2" style={{fontSize:'18px',marginTop:'16px'}}>
                  {form.values.sameAsRequestor === 'someone' ? _.capitalize(`${t('REQUESTOR')} ${t('NAME')}`) : _.capitalize(`${t('CUSTOMER')} ${t('NAME')}`)}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    name={'firstName'}
                    id="firstName"
                    label={
                      `${t('FIRST_NAME')} ${'*'}`
                    }
                    inputProps={{ maxLength: 90, fontSize: 12 }}
                    {...form.props('requestorFirstName')}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    name={'middleName'}
                    id="middleName"
                    label={
                      t('MIDDLE_NAME')}
                    inputProps={{ maxLength: 90, fontSize: 12 }}
                    {...form.props('requestorMiddleName')}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    name={'lastName'}
                    id="lastName"
                    label={
                      `${t('LAST_NAME')} ${'*'}`}
                    inputProps={{ maxLength: 90, fontSize: 12 }}
                    {...form.props('requestorLastName')}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} style={{ marginTop: '8px' }}>
              <Grid item xs={12} md={12}>
                  <Typography variant="h2" style={{fontSize:'18px',marginTop:'16px'}}>
                    {form.values.sameAsRequestor === 'someone' ? _.capitalize(`${t('REQUESTOR')} ${t('CONTACT_INFORMATION')}`) : _.capitalize(`${t('CUSTOMER')} ${t('CONTACT_INFORMATION')}`)}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Dropdown
                    label={
                      `${t('PHONE_TYPE')} ${'*'}`}
                    selectionList={[
                      { value: t('HOME_PHONE'), label: t('HOME_PHONE') },
                      { value: t('OFFICE_PHONE'), label: t('OFFICE_PHONE') },
                      { value: t('MOB_PHONE'), label: t('MOB_PHONE') },
                    ]}
                    {...form.props('requestorPhoneType')}
                    error={Boolean(form.errors.requestorPhoneType)}
                  />

                  {form.errors.requestorPhoneType && (
                    <FormHelperText
                      className={classes.errorMessage}
                      error={true}
                    >
                      {form.errors.requestorPhoneType}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12} md={6}>
                  <PhoneTextField
                    name={'phoneValue'}
                    id="RequestorContactNumber"
                    aria-labelledby={'RequestorContactNumber'}
                    label={
                      `${t('CONTACT_NUMBER')} ${'*'}`}
                    css={{ margin: 0 }}
                    fullWidth
                    variant="outlined"
                    {...form.props('requestorPhoneValue')}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} style={{ marginTop: '8px' }}>              
                <Grid item xs={12} md={6}>
                  <TextField
                    name={'email'}
                    id="nameUpdateInfo"
                    label={
                      `${t('EMAIL_ADDRESS')} ${'*'}`}
                    inputProps={{ maxLength: 90, fontSize: 16 }}
                    {...form.props('requestorEmail')}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} style={{ marginTop: '8px' }}>
                <Grid item xs={12} md={6}>
                  <TextField
                    name={'confirmEmail'}
                    id="confirmEmail"
                    label={_.capitalize(
                      `${t('CONFIRM_EMAIL_ADDRESS_LABEL')} ${'*'}`,
                    )}
                    inputProps={{ maxLength: 90, fontSize: 16 }}
                    {...form.props('requestorConfirmEmail')}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3} alignItems="center">
                <Grid item xs={12} md={12}>
                  <Typography variant="h2" style={{fontSize:'18px',marginTop:'16px'}}>
                    {form.values.sameAsRequestor === 'someone' ?  _.capitalize(`${t('REQUESTOR')} ${t('MAILING_ADDRESS')}`) : _.capitalize(`${t('CUSTOMER')} ${t('MAILING_ADDRESS')}`)}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField
                    name={'address'}
                    id="streetAddressUpdateInfo"
                    label={`${t('STREET_ADDRESS_UPDATE_INFO_LABEL')} ${'*'}`}
                    inputProps={{ maxLength: 60, fontSize: 16 }}
                    {...form.props('requestorAddress')}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    name={'city'}
                    id="cityUpdateInfo"
                    label={`${t('CITY')} ${'*'}`}
                    inputProps={{ maxLength: 20, fontSize: 16 }}
                    {...form.props('requestorCity')}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Dropdown
                    label={t('STATE_PROVINCE_LABEL')}
                    selectionList={statesAndProvincesGrouping.unitedStates.map(
                      stateValue => ({
                        value: stateValue,
                        label: getStateName(stateValue),
                      }),
                    )}
                    {...form.props('requestorState')}
                    error={Boolean(form.errors.requestorState)}
                  />

                  {form.errors.requestorState && (
                    <FormHelperText
                      className={classes.errorMessage}
                      error={true}
                    >
                      {form.errors.requestorState}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12} md={6}>
                  <ZipInputField
                    name={'zip'}
                    id="zipCodeUpdateInfo"
                    label={`${t('ZIP_CODE')} ${'*'}`}
                    {...form.props('requestorZip')}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item md={12}>
                  <Typography
                    variant="h2"
                    className={classes.subHeading}
                    style={{ marginTop: '16px' }}
                  >
                    {t('REQUESTOR_CONTACT_PREFERENCE')}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                  <RadioGroup
                    row
                    id={t('CONTACT_MAIL_SELECTION')}
                    aria-labelledby={t('CONTACT_MAIL_SELECTION')}
                    name={'contactMailType'}
                    value={form.props('requestorContactMailType').value}
                    onChange={form.props('requestorContactMailType').onChange}
                    onBlur={form.props('requestorContactMailType').onBlur}
                  >
                    <FormControlLabel
                      value={'email'}
                      control={<Radio color={'primary'} />}
                      label={
                        <Typography className={classes.radioLabel}>
                          {t('EMAIL')}
                        </Typography>
                      }
                    />

                    <FormControlLabel
                      value={'byMail'}
                      control={<Radio color={'primary'} />}
                      label={
                        <Typography className={classes.radioLabel}>
                          {t('BY_MAIL')}
                        </Typography>
                      }
                    />
                  </RadioGroup>
                </Grid>
              </Grid>
              <Divider className={classes.dividerPadding} />
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <Typography variant="h2" style={{ fontSize: '18px' }}>
                    {t('ID_INFORMATION')}
                  </Typography>
                </Grid>
                <Grid item md={12}>
                  <Typography className={classes.text}>
                    {t('SELECT_ID_TYPE_TEXT')}
                  </Typography>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Dropdown
                      label={`${t('PLEASE_SELECT_ID_TYPE')} ${'*'}`}
                      selectionList={[
                        {
                          value: t('SOCIAL_SECURITY_NUMBER'),
                          label: t('SOCIAL_SECURITY_NUMBER'),
                        },
                        {
                          value: `${t('DRIVERS_LICENSE')} / ${t(
                            'STATE_ID_CARD',
                          )}`,
                          label: `${t('DRIVERS_LICENSE')} / ${t(
                            'STATE_ID_CARD',
                          )}`,
                        },
                        {
                          value: t('MATRICULA_ID'),
                          label: t('MATRICULA_ID'),
                        },
                        { value: t('PASSPORT'), label: t('PASSPORT') },
                        {
                          value: t('RESIDENT_ALIEN'),
                          label: t('RESIDENT_ALIEN'),
                        },
                        { value: t('VISA_ID'), label: t('VISA_ID') },
                        {
                          value: t('MILITARY_ID'),
                          label: t('MILITARY_ID'),
                        },
                        {
                          value: t('OR_TRIBAL_ID'),
                          label: t('OR_TRIBAL_ID'),
                        },
                      ]}
                      {...form.props('requestorIdType')}
                      error={Boolean(form.errors.requestorIdType)}
                    />

                    {form.errors.requestorIdType && (
                      <FormHelperText
                        className={classes.errorMessage}
                        error={true}
                      >
                        {form.errors.requestorIdType}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      name={'idValue'}
                      aria-labelledby={'idValue'}
                      id={'idValue'}
                      label={`${t('ID_VALUE')} ${'*'}`}
                      fullWidth
                      variant="outlined"
                      {...form.props('requestorIdValue')}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Divider className={classes.dividerPadding} />
              <Box className={classes.boxPadding}>
                <Typography variant="body1">{t('REQUEST_REPORT')}</Typography>
              </Box>
              <Box className={classes.boxPadding}>
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ alignSelf: 'flex-start' }}
                      color="primary"
                      {...form.props('personalInfoReport')}
                    />
                  }
                  label={t('OCPA_REQUEST_CHECKBOX_PERSONAL_INFO_REPORT_DESC')}
                />
              </Box>
              <Box className={classes.boxPadding}>
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ alignSelf: 'flex-start' }}
                      color="primary"
                      {...form.props('deleteInfo')}
                    />
                  }
                  label={t('OCPA_DELETE_CHECKBOX_MY_PERSONAL_INFO_DESC')}
                />
              </Box>
              <Box className={classes.boxPadding}>
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ alignSelf: 'flex-start' }}
                      {...form.props('marketingCommunication')}
                      color="primary"
                    />
                  }
                  label={t('OCPA_OPT_CHECKBOX_OUT_MARKETING_DESC')}
                />
              </Box>
              {ocpaOptionsReqError && (
                <Box
                  className={classes.discFalse}
                  style={{ padding: '.5rem 1.5rem' }}
                >
                  <Typography variant="body1">
                    {t('OCPA_OPTIONS_REQUIRED')}
                  </Typography>
                </Box>
              )}
              <Divider className={classes.dividerPadding} />
              {form.values.sameAsRequestor === 'someone' && (<Grid>
              <Box>
                <Typography variant="h2" style={{ fontSize: '18px' }}>
                  {t('CUSTOMER_INFO')}
                </Typography>
                <Typography className={classes.text}>
                  {richT('OCPA_CUSTOMER_INFO_SECTION_TEXT')}
                </Typography>
              </Box>
              <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                  <Typography variant="h2" style={{fontSize:'18px',marginTop:'16px'}}>
                    {_.capitalize(`${t('CUSTOMER')} ${t('Name')}`)}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    name={'customerFirstName'}
                    id="customer_firstName"
                    label={
                      `${t('FIRST_NAME')} ${'*'}`}
                    inputProps={{ maxLength: 90, fontSize: 12 }}
                    {...form.props('customerFirstName')}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    name={'customerMiddleName'}
                    id="customer_middleName"
                    label={t('MIDDLE_NAME')}
                    inputProps={{ maxLength: 90, fontSize: 12 }}
                    {...form.props('customerMiddleName')}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    name={'customerLastName'}
                    id="customerLastName"
                    label={
                      `${t('LAST_NAME')} ${'*'}`}
                    inputProps={{ maxLength: 90, fontSize: 12 }}
                    {...form.props('customerLastName')}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                  <Typography variant="h2" style={{fontSize:'18px',marginTop:'16px'}}>
                    {_.capitalize(`${t('CUSTOMER')} ${t('CONTACT_INFORMATION')}`)}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6} style={{ marginTop: '15px' }}>
                  <Dropdown
                    label={
                      `${t('PHONE_TYPE')} ${'*'}`}
                    aria-labelledby={'CustomerPhoneType'}
                    id={'CustomerPhoneType'}
                    selectionList={[
                      { value: t('HOME_PHONE'), label: t('HOME_PHONE') },
                      { value: t('OFFICE_PHONE'), label: t('OFFICE_PHONE') },
                      { value: t('MOB_PHONE'), label: t('MOB_PHONE') },
                    ]}
                    {...form.props('customerPhoneType')}
                    error={Boolean(form.errors.customerPhoneType)}
                  />

                  {form.errors.customerPhoneType && (
                    <FormHelperText
                      className={classes.errorMessage}
                      error={true}
                    >
                      {form.errors.customerPhoneType}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12} md={6}>
                  <PhoneTextField
                    name={'customerPhoneValue'}
                    label={
                      `${t('CONTACT_NUMBER')} ${'*'}`
                    }
                    id={'CustomerPhoneNumber'}
                    fullWidth
                    variant="outlined"
                    {...form.props('customerPhoneValue')}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
              
              <Grid item xs={12} md={6}>
                <TextField
                  name={'customerEmail'}
                  id="customerEmail"
                  label={
                    `${t('EMAIL_ADDRESS')} ${'*'}`}
                  inputProps={{ maxLength: 90, fontSize: 16 }}
                  {...form.props('customerEmail')}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} style={{ marginTop: '5px' }}>
                <TextField
                  name={'customerConfirmEmail'}
                  id="customerConfirmEmail"
                  label={_.capitalize(
                    `${t('CONFIRM_EMAIL_ADDRESS_LABEL')} ${'*'}`,
                  )}
                  inputProps={{ maxLength: 90, fontSize: 16 }}
                  {...form.props('customerConfirmEmail')}
                />
              </Grid>
            </Grid>
              <Grid container spacing={3} alignItems="center">
                <Grid item xs={12} md={12}>
                  <Typography variant="h2" style={{fontSize:'18px',marginTop:'16px'}}>
                    {_.capitalize(`${t('CUSTOMER')} ${t('MAILING_ADDRESS')}`)}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField
                    name={'customerAddress'}
                    id="customerAddress"
                    label={`${t('STREET_ADDRESS_UPDATE_INFO_LABEL')} ${'*'}`}
                    inputProps={{ maxLength: 60, fontSize: 16 }}
                    {...form.props('customerAddress')}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    name={'customerCity'}
                    id="customerCity"
                    label={`${t('CITY')} ${'*'}`}
                    inputProps={{ maxLength: 20, fontSize: 16 }}
                    {...form.props('customerCity')}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Dropdown
                    label={t('STATE_PROVINCE_LABEL')}
                    selectionList={statesAndProvincesGrouping.unitedStates.map(
                      stateValue => ({
                        value: stateValue,
                        label: getStateName(stateValue),
                      }),
                    )}
                    {...form.props('customerState')}
                    error={Boolean(form.errors.customerState)}
                  />

                  {form.errors.customerState && (
                    <FormHelperText
                      className={classes.errorMessage}
                      error={true}
                    >
                      {form.errors.customerState}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12} md={6}>
                  <ZipInputField
                    name={'customerZip'}
                    id="customerZip"
                    label={`${t('ZIP_CODE')} ${'*'}`}
                    {...form.props('customerZip')}
                  />
                </Grid>
              </Grid>
              
              </Grid>)}
              <Grid container spacing={3}>
                <Grid
                  item
                  xs={12}
                  style={{ marginTop: '5px', paddingBottom: 0 }}
                >
                  <Typography>{`${t('CONFIRMATION')} ${'*'}`}</Typography>
                </Grid>
              </Grid>
              <Box className={classes.boxPadding}>
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ alignSelf: 'flex-start', paddingTop: 20 }}
                      name={'disclaimer'}
                      {...form.props('disclaimer')}
                      color="primary"
                    />
                  }
                  label={
                    <Box
                      className={
                        form.errors.disclaimer
                          ? classes.discFalse
                          : classes.discTrue
                      }
                    >
                      <Typography variant="body1">                      
                        {richT('PUBLIC_OCPA_DESCLAIMER_TEXT')}
                      </Typography>
                    </Box>
                  }
                />
              </Box>
              {form.errors.disclaimer && (
                <FormHelperText className={classes.errorMessage} error={true}>
                  {form.errors.disclaimer}
                </FormHelperText>
              )}
              <Grid
                container
                spacing={2}
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  padding: '15px 0px',
                }}
              >
                <Grid item className={classes.cancelButton}>
                  <TextLink to={ROUTES.PRIVACY_POLICY}>{t('CANCEL')}</TextLink>
                </Grid>
                <Grid item>
                  <PGEButton
                    type={'submit'}
                    variant={'contained'}
                    color={'primary'}
                    className={classes.submit}
                  >
                    {t('SUBMIT')}
                  </PGEButton>
                </Grid>
              </Grid>
            </form>
          </Paper>
        )}
      </Grid>
    </Grid>
  );
};
export default OCPA;
